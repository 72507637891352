// Generated by CoffeeScript 1.10.0
(function() {
  (function(p, $, d, w) {
    var Configurator;
    Configurator = (function() {
      var getCookie, self, setCookie;

      self = false;

      function Configurator() {
        self = this;
        this.isMobileDevice = /Android|Kindle|Silk|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
        navigator.sayswho = (function() {
          var M, tem, ua;
          ua = navigator.userAgent;
          tem = void 0;
          M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
          if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
          }
          if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem !== null) {
              return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
          }
          M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
          if ((tem = ua.match(/version\/(\d+)/i)) !== null) {
            M.splice(1, 1, tem[1]);
          }
          return M.join(' ');
        })();
      }

      Configurator.prototype.isRunnable = function() {
        return $('#configuratore-piatto-unico-desktop').length > 0 && $(w).width() >= 768;
      };

      Configurator.prototype.ready = function() {
        self = this;
        $.extend($.expr[':'], {
          'containsIN': function(elem, i, match, array) {
            return (elem.textContent || elem.innerText || '').toLowerCase().indexOf((match[3] || '').toLowerCase()) >= 0;
          }
        });
        return $(w).load(function() {
          if (self.isRunnable()) {
            self.bindings();
            return self.load();
          }
        });
      };

      Configurator.prototype.load = function() {
        if (navigator.sayswho.indexOf("Safari 8") > -1) {
          $('.flex-container').addClass('old-browser');
        }
        if ($(w).width() > 768) {
          $('[data-toggle="tooltip"]').tooltip({
            'html': true
          });
        }
        if ($('#lista-piatti ul.dishes-list li').length > 0) {
          $('#lista-piatti').show();
          $('#lista-piatti ul.dishes-list').show();
        }
        $('#lista-piatti ul.dishes-list li').each(function() {
          return $('.single-dish', this).height($('.single-dish', this).width());
        });
        if ($(w).width() > 768) {
          return $('#piatto .save-message-container').appendTo($('#piatto .circle'));
        }
      };

      Configurator.prototype.showLoading = function() {
        return $('#configuratore-piatto-unico-desktop .loading').show();
      };

      Configurator.prototype.hideLoading = function() {
        return $('#configuratore-piatto-unico-desktop .loading').hide();
      };

      Configurator.prototype.clearMessages = function() {
        return w.setTimeout(function() {
          $('#messages').html(" ");
          $('#piatto .save-message-text').html(" ");
          $('#piatto .save-message-container').addClass("hidden");
          self.clearDish();
        }, 8000);
      };

      Configurator.prototype.showCreatedDish = function(dishId) {
        self.getDishTemplate(dishId);
        return self.getDishDetails(dishId);
      };

      Configurator.prototype.saveDish = function() {
        var canSave, dishData, isGuest, j, len, method, obj, url;
        if ($('.dish-name input').val() === '') {
          $('.dish-name input').focus().addClass("require");
          return;
        } else {
          $('.dish-name input').removeClass("require");
          $('#piatto input.dish-name-hidden').val($('.dish-name input').val());
        }
        canSave = true;
        $('#piatto .circle input').each(function() {
          if ($(this).val() === '') {
            canSave = false;
          }
        });
        if (!canSave) {
          $('#form-create-dish .error-save-dish').show();
          return false;
        } else {
          $('#form-create-dish .error-save-dish').hide();
        }
        url = $('#form-create-dish').attr('action');
        method = $('#form-create-dish').attr('method');
        self.showLoading();
        dishData = $('#form-create-dish').serializeArray();
        isGuest = false;
        for (j = 0, len = dishData.length; j < len; j++) {
          obj = dishData[j];
          if (obj.name === 'user_id' && obj.value === '0') {
            isGuest = true;
          }
        }
        dishData = $('#form-create-dish').serialize();
        return $.ajax({
          type: method,
          url: url,
          dataType: "json",
          data: dishData,
          success: function(data) {
            if (data.dish_id) {
              self.clearMessages();
              self.showCreatedDish(data.dish_id);
              self.hideLoading();
              $('.dish-name button.btn-standard').blur();
              self.goToNextStep();
              if (isGuest) {
                setCookie("casadivitaSavedDish", $('#form-create-dish').serialize());
              }
            } else {
              self.clearMessages();
              self.hideLoading();
              $('#messages').html('<span>' + data.message + '</span>');
            }
          }
        });
      };

      Configurator.prototype.showIngredients = function(elem) {
        var child, parent, postFiltered;
        $('.container-search input.search-ingredients').val('');
        $('.container-search .filters .filter').removeClass('active');
        self.filterRicette('', []);
        $('#ingredients-list .panel-collapse li.list-group-item').not(elem).removeClass("active");
        if (!$(elem).hasClass("active")) {
          $('.wrap-ingredients-list').insertAfter(elem).addClass('opened').slideDown();
          $(elem).addClass("active");
          parent = $(elem).data("parent");
          child = $(elem).data("children");
          $('.post-ingredients li').css('clear', 'none').removeClass('active').hide();
          postFiltered = $('.post-ingredients li').filter(function() {
            return $.inArray(child.toString(), $(this).data('tipologia-id').toString().split(',')) !== -1;
          });
          postFiltered.show().addClass('active');
          $('.post-ingredients li.active').filter(':even').css('clear', 'both');
          if ($('.post-ingredients li.active').length < 3) {
            $('.post-ingredients').height(230).jScrollPane({
              mouseWheelSpeed: 30
            });
          } else {
            $('.post-ingredients').height(450).jScrollPane({
              mouseWheelSpeed: 30
            });
          }
          if (w.bLazy !== void 0) {
            w.bLazy.revalidate();
            return $('.post-ingredients').on('jsp-scroll-y', function(event) {
              return w.bLazy.revalidate();
            });
          }
        }
      };

      Configurator.prototype.updateImageToDish = function(imgSrc, type, id) {
        var $contImgElem, $imgElement;
        $('#piatto .slice[data-term-slug="' + type + '"]').addClass('fill');
        if ($('#piatto .slice[data-term-slug="' + type + '"] .background-img-dish').length === 0) {
          $contImgElem = $('<div/>').addClass('background-img-dish').appendTo($('#piatto .slice[data-term-slug="' + type + '"]'));
          $imgElement = $('<img/>').appendTo($contImgElem);
        } else {
          $imgElement = $('#piatto .slice[data-term-slug="' + type + '"] .background-img-dish img');
        }
        $imgElement.attr('src', imgSrc);
        $('#piatto .slice[data-term-slug="' + type + '"] input').val(id);
        $('#form-download-recipes span[data-term-slug="' + type + '"] input').val(id);
        return $('#piatto .slice[data-term-slug="' + type + '"] .text-container').hide();
      };

      Configurator.prototype.updateDishesList = function(id) {
        var dataSend, url;
        url = $('#form-create-dish').attr('action');
        dataSend = {
          'is_ajax': 1,
          'dish_id': id,
          'action': 'get_dish_template'
        };
        return $.ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.template !== '') {
              $('#lista-piatti').show();
              $('#lista-piatti .dishes-list').delay(2500).prepend(data.template);
            }
          }
        });
      };

      Configurator.prototype.moveToDish = function(element) {
        var idIngredient, img, selector, type;
        if (element.children('img').hasClass('b-loaded')) {
          img = element.children('img').attr("src");
        } else {
          img = element.children('img').attr("data-src");
        }
        idIngredient = element.data("ingredient-id");
        type = element.data("parent-slug");
        self.updateImageToDish(img, type, idIngredient);
        selector = element.parents('.panel-collapse').attr("aria-labelledby");
        return $('#' + selector + ' a').trigger("click");
      };

      Configurator.prototype.filterRicette = function(text, filtersValue) {
        var countMatch;
        if (filtersValue == null) {
          filtersValue = [];
        }
        if (text !== '' || filtersValue.length > 0) {
          $('.post-ingredients li.active').addClass('filtered');
        } else {
          $('.post-ingredients li.active').removeClass('filtered');
        }
        countMatch = 0;
        if (filtersValue.length > 0 && text === '') {
          $('.post-ingredients li.active').each(function() {
            if ($(this).attr('data-preparation-time') !== '' && $.inArray($(this).attr('data-preparation-time'), filtersValue) >= 0) {
              $(this).removeClass('filtered');
              countMatch++;
            }
          });
        }
        if (text !== '' && filtersValue.length === 0) {
          $('.post-ingredients li.active a.name:containsIN(' + text + ')').each(function() {
            $(this).parents('li').removeClass('filtered');
            countMatch++;
          });
        }
        if (text !== '' && filtersValue.length > 0) {
          $('.post-ingredients li.active a.name:containsIN(' + text + ')').each(function() {
            if ($(this).parents('li').attr('data-preparation-time') !== '' && $.inArray($(this).parents('li').attr('data-preparation-time'), filtersValue) >= 0) {
              $(this).parents('li').removeClass('filtered');
              countMatch++;
            }
          });
        }
        if ((text !== '' || filtersValue.length > 0) && countMatch === 0) {
          $('.post-ingredients').hide();
          $('.wrap-ingredients-list .text-no-results').show();
        } else {
          $('.wrap-ingredients-list .text-no-results').hide();
          $('.post-ingredients').show();
        }
        $('.post-ingredients li.active:not(.filtered)').css('clear', 'none');
        $('.post-ingredients li.active:not(.filtered)').filter(':even').css('clear', 'both');
        if ($('.post-ingredients li.active:not(.filtered)').length < 3) {
          return $('.post-ingredients').height(230).jScrollPane({
            mouseWheelSpeed: 30
          });
        } else {
          return $('.post-ingredients').height(450).jScrollPane({
            mouseWheelSpeed: 30
          });
        }
      };

      Configurator.prototype.clearDish = function() {
        $('#piatto .slice').removeClass('fill');
        $('#piatto .background-img-dish').remove();
        $('#piatto .slice input').val('');
        $('#piatto .slice .text-container').show();
        $('#piatto button').blur();
        return $('.dish-name input').val('');
      };

      Configurator.prototype.restart = function() {
        self.clearDish();
        $('.step-wrap.active-step').removeClass('active-step');
        $('.step-wrap:first').addClass('active-step');
        $('#dish-saved div').html('');
        $('html,body').stop(false, true).animate({
          scrollTop: $('#configuratore-piatto-unico-desktop').offset().top - 100
        }, 'slow');
        return $('a.link-mealplan').blur();
      };

      Configurator.prototype.deleteDish = function(elem, id) {
        var sendData, url;
        url = $(elem).data("url");
        sendData = {
          'action': 'delete_dish',
          'id': id,
          'is_ajax': 1
        };
        return $.ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: sendData,
          success: function(data) {
            $.each(data, function(key, message) {
              $('#messages').html('<span>' + message + '</span>');
              self.clearMessages();
              $('#lista-piatti ul.dishes-list li[data-dish-id="' + id + '"]').remove();
            });
            if ($('#lista-piatti ul.dishes-list li').length === 0) {
              $('#lista-piatti').hide();
            }
          }
        });
      };

      Configurator.prototype.getRecipePdf = function() {
        var carboId, proteineId, sendData, url, verdureId;
        url = $('#form-create-dish').attr('action');
        proteineId = $('#form-create-dish input[name="proteine"]').val();
        carboId = $('#form-create-dish input[name="carboidrati"]').val();
        verdureId = $('#form-create-dish input[name="verdure"]').val();
        sendData = {
          'action': 'get_recipes_pdf',
          'proteine_id': proteineId,
          'carboidrati_id': carboId,
          'verdure_id': verdureId,
          'is_ajax': 1
        };
        return $.ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: sendData,
          success: function(data) {
            var pdflist;
            pdflist = [];
            if (data.proteine_pdf !== void 0 && data.proteine_pdf !== false) {
              pdflist.push(data.proteine_pdf);
            }
            if (data.carboidrati_pdf !== void 0 && data.carboidrati_pdf !== false) {
              pdflist.push(data.carboidrati_pdf);
            }
            if (data.verdure_pdf !== void 0 && data.verdure_pdf !== false) {
              pdflist.push(data.verdure_pdf);
            }
            self.openLinkList(pdflist);
          }
        });
      };

      Configurator.prototype.getDishTemplate = function(dishId) {
        var dataSend, url;
        url = $('#form-create-dish').attr('action');
        dataSend = {
          'is_ajax': 1,
          'dish_id': dishId,
          'action': 'get_dish_simple_template'
        };
        return $.ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.template !== '') {
              $('#dish-saved .dish-wrap').html(data.template);
            }
          }
        });
      };

      Configurator.prototype.getDishDetails = function(dishId) {
        var dataSend, url;
        url = $('#form-create-dish').attr('action');
        dataSend = {
          'is_ajax': 1,
          'dish_id': dishId,
          'type': 'user',
          'action': 'get_dish_recipes'
        };
        return $.ajax({
          type: "POST",
          url: url,
          dataType: "json",
          data: dataSend,
          success: function(data) {
            if (data.recipes_template !== '') {
              $('#dish-saved .recipes-wrap').html(data.recipes_template);
              $('#dish-saved .name').text(data.dish_name);
            }
          }
        });
      };

      Configurator.prototype.openLinkList = function(arr) {
        return $.each(arr, function(key, link) {
          if (link !== '') {
            return w.open(link);
          }
        });
      };

      Configurator.prototype.goToNextStep = function() {
        var nextActiveStep;
        nextActiveStep = $('.step-wrap.active-step').next();
        $('.step-wrap.active-step').removeClass('active-step');
        nextActiveStep.addClass('active-step');
        return setTimeout(function() {
          $('html,body').stop(false, true).animate({
            scrollTop: nextActiveStep.offset().top - 100
          }, 'slow');
        }, 750);
      };

      Configurator.prototype.goToPrevStep = function() {
        var prevActiveStep;
        prevActiveStep = $('.step-wrap.active-step').prev();
        $('.step-wrap.active-step').removeClass('active-step');
        prevActiveStep.addClass('active-step');
        return setTimeout(function() {
          $('html,body').stop(false, true).animate({
            scrollTop: prevActiveStep.offset().top - 100
          }, 'slow');
        }, 750);
      };

      Configurator.prototype.getActiveFilters = function() {
        return $('.wrap-ingredients-list .container-search .filters .filter.active');
      };

      Configurator.prototype.getActiveFiltersValue = function() {
        var activeValues;
        activeValues = [];
        $.each(self.getActiveFilters(), function(index, filter) {
          activeValues.push($(filter).attr('data-value'));
        });
        return activeValues;
      };

      Configurator.prototype.bindings = function() {
        $('.container-search input.search-ingredients').on("keyup", function(event) {
          var activeValues;
          activeValues = self.getActiveFiltersValue();
          return self.filterRicette($(this).val(), activeValues);
        });
        $('.wrap-ingredients-list .container-search .filters .filter').on('click', function(event) {
          var activeValues;
          $(this).toggleClass('active');
          activeValues = self.getActiveFiltersValue();
          return self.filterRicette($('.container-search input.search-ingredients').val(), activeValues);
        });
        $('#ingredients-list a.ingredient').on("click", function(event) {
          var nextStep;
          self.moveToDish($(this));
          nextStep = true;
          $('#piatto .circle input').each(function() {
            if ($(this).val() === '') {
              nextStep = false;
            }
          });
          if (nextStep) {
            return $('#button-next-step').attr('disabled', null);
          }
        });
        $(w).resize(function() {
          $('#lista-piatti ul.dishes-list li').each(function() {
            return $('.single-dish', this).height($('.single-dish', this).width());
          });
          return $('.jspScrollable').each(function() {
            var jScrollApi;
            jScrollApi = $(this).data("jsp");
            return jScrollApi.reinitialise();
          });
        });
        return $('#accordion-ingredients .panel-heading a').on("click", function(event) {
          $('.container-search input.search-ingredients').val('');
          self.filterRicette('');
          if (!$(this).hasClass('collapsed')) {
            $('.wrap-ingredients-list').removeClass('opened').slideUp();
            $('#ingredients-list .panel-collapse li.list-group-item').removeClass("active");
            $('.jspScrollable').each(function() {
              var jScrollApi;
              jScrollApi = $(this).data("jsp");
              return jScrollApi.destroy();
            });
          }
          $('.post-ingredients li').css('clear', 'none').removeClass('active').hide();
          return $('.jspScrollable').each(function() {
            var jScrollApi;
            jScrollApi = $(this).data("jsp");
            return jScrollApi.reinitialise();
          });
        });
      };

      setCookie = function(name, value) {
        var cookie, date, expires;
        date = new Date();
        date.setTime(date.getTime() + (10 * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
        cookie = name + "=" + value + expires + "; path=/";
        document.cookie = cookie;
        return document.cookie;
      };

      getCookie = function(name) {
        var c, ca, i, nameEQ;
        nameEQ = name + "=";
        ca = document.cookie.split(";");
        i = 0;
        while (i < ca.length) {
          c = ca[i];
          while (c.charAt(0) === " ") {
            c = c.substring(1, c.length);
          }
          if (c.indexOf(nameEQ) === 0) {
            return c.substring(nameEQ.length, c.length);
          }
          i++;
        }
        return null;
      };

      return Configurator;

    })();
    w.configurator = new Configurator();
    configurator.ready();
  })($, jQuery, document, window);

}).call(this);
